$main-background-color: white;
$header-background-color: #F7F9FC;
$data-background-color: #E9EBF8;
$font-color: #0C0B1A;
$secondary-font-color: #8C8C8C;
$black-color: #000000;
$gray-color: #383838;
$blue-color: #416CDE;
$light-blue-color: #BCCEFD;
$light-gray-color: #8F9BB2;
$success-color: #27AE60;
$failure-color: #EB5757;
$upgrade-color: #DB2156;

$light-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);

$header-section-color : #fbfcfe;
$content-section-color: #f8f9fc;

$text-font-family: Source Sans Pro, Roboto, Lucida Grande, Tahoma, sans-serif;

:export {
  mainBackgroundColor: $main-background-color;
  headerBackgroundColor: $header-background-color;
  dataBackgroundColor: $data-background-color;
  fontColor: $font-color;
  secondaryFontColor: $secondary-font-color;
  grayColor: $gray-color;
  lightGrayColor: $light-gray-color;
  blueColor: $blue-color;
  lightBlueColor: $light-blue-color;
  successColor: $success-color;
  failureColor: $failure-color;
  upgradeColor: $upgrade-color;
  lightShadow: $light-shadow;
  contentSectionColor: $content-section-color;
  textFontFamily: $text-font-family
}
