@import '../../variables.module'

.row
  display: flex
  background: $main-background-color
  min-height: 46px
  max-height: 46px
  align-items: center
  padding: 0 8px
  border-radius: 4px
  cursor: pointer
  border: solid 1px transparent
  margin-right: 10px
  &:not(:first-child)
    margin-top: 10px

  &:hover
    border: solid 1px lighten(#4253a5, 20%)

.rowSelected
  border: 1px $blue-color solid

.resolvedName
  text-overflow: ellipsis
  white-space: nowrap
  color: $secondary-font-color
  padding-left: 4px
  padding-right: 10px
  display: flex

  .resolvedNameText
    font-family: 'Roboto', sans-serif
    font-size: 12px
    font-weight: 400
    letter-spacing: -0.48px

.headingModeText
  font-family: 'Source Sans Pro', sans-serif !important
  letter-spacing: unset !important

.timestamp
  font-size: 12px
  color: $secondary-font-color
  padding-left: 12px
  flex-shrink: 0
  width: 185px
  text-align: left

.padlock
  margin-top: -60px

.padlock img
  height: 14px
  margin-top: 12px
  margin-left: -2px

.endpointServiceContainer
  display: flex
  flex-direction: column
  overflow: hidden
  padding-right: 10px
  padding-top: 4px
  flex-grow: 1
  padding-left: 10px

.separatorRight
  display: flex
  border-right: 1px solid $data-background-color
  padding-right: 12px

.separatorLeft
  display: flex
  padding: 4px
  padding-left: 12px

.tcpInfo
  font-family: 'Roboto', sans-serif
  font-size: 12px
  letter-spacing: -0.48px
  color: $secondary-font-color
  margin-top: 5px
  margin-bottom: 5px

.port
  margin-right: 5px

.ip
  margin-left: 5px

@media (max-width: 1760px)
  .timestamp
    display: none
  .separatorRight
    border-right: 0px

@media (max-width: 1340px)
  .separatorRight
    display: none
