@import '../../../variables.module'

.TrafficPageContainer
    padding-top: 10px
    display: flex
    flex-grow: 1
    overflow: hidden
    background-color: $data-background-color

.TrafficPageListContainer
    position: relative
    display: flex
    flex-grow: 1
    overflow: hidden
    padding-left: 24px
    flex-direction: column
