@import '../../variables.module'

.header
  height: 60px
  display: flex
  align-items: center
  padding: 5px 24px
  justify-content: space-between

  .proTag
    background-color: $blue-color /* Blue color */
    color: white
    padding: 2px 4px
    border-radius: 2px
    margin-top: 5px
    margin-left: 2px
    font-size: 10px
    font-weight: bold

  .logo
    height: 40px
    width: 178px

  .description
    margin-top: 2px
    margin-left: 10px
    font-size: 11px
    font-weight: bold
    color: $light-blue-color

  .nodeLimit
    margin-top: 5px
    margin-left: 12px
    font-size: 11px
    font-weight: bold
    line-height: 13px
    color: $light-gray-color
    text-transform: capitalize

  .extra
    margin-top: 2px
    margin-left: 8px
    font-size: 11px
    font-weight: bold

  .icon
    font-size: small
    vertical-align: bottom
    padding-left: 4px

  .upgradeButton
    color: $upgrade-color
    border: 1px solid $upgrade-color
    padding: 5px 10px 5px 10px
    border-radius: 6px
    gap: 10px
    margin-top: 5px
    margin-left: 12px
    text-transform: none !important

  .textUpgradeButton
    font-size: 12px
    font-weight: 700
    line-height: 14px
    letter-spacing: 0
    text-align: center

  .failure
    color: $failure-color

  .upgrade
    color: $upgrade-color

  .success
    color: $success-color

  .headerIcon
    cursor: pointer

  .entLogo
    cursor: pointer

.obtainLicenseTooltip    
  padding: 8px
  border: 1px solid $light-blue-color
  border-radius: 2px
  background-color: $data-background-color

.obtainLicenseTooltipArrow  
  color: $light-blue-color

.obtainLicenseTooltipText
  font-family: 'Source Sans Pro', sans-serif
  font-size: 11px
  font-weight: 400
  line-height: normal
  letter-spacing: 0.33px
  color: $font-color