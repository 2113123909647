@import '../../../variables.module'

.base
    box-sizing: border-box
    border-radius: 4px
    width: 41px
    height: 20px
    padding: 2px 8px
    font-family: 'Roboto', sans-serif
    font-size: 14px
    display: inline-block
    text-align: center
    font-weight: 700
    margin-left: 3px

.neutral
    background: gray
    color: black

.success
    background: $success-color
    color: white

.failure
    background: $failure-color
    color: white
